import request from '@/utils/request'

// 获取商家列表
export function getBusinesses(params) {
  return request({
    method: 'get',
    url: 'business-take/businesses',
    params
  })
}

// 获取订单列表
export function getOrders(params) {
  return request({
    method: 'get',
    url: 'business-take/orders',
    params
  })
}

// 获取订单详情
export function getOrderDetail(params) {
  return request({
    method: 'get',
    url: 'business-take/detail',
    params
  })
}

// 完成提货
export function finishTake(data) {
  return request({
    method: 'post',
    url: 'business-take/finished',
    data
  })
}

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": _vm.businessName,
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-fixed-container safe-area-padding-bottom"
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _vm._l(_vm.list, function (order) {
    return _c('van-checkbox-group', {
      key: order.id,
      staticClass: "order",
      model: {
        value: _vm.checkOrders,
        callback: function callback($$v) {
          _vm.checkOrders = $$v;
        },
        expression: "checkOrders"
      }
    }, [_c('van-checkbox', {
      staticClass: "order-check",
      attrs: {
        "name": order.id
      }
    }), _c('van-cell', {
      attrs: {
        "is-link": "",
        "center": ""
      },
      on: {
        "click": function click($event) {
          return _vm.toDetailView(order);
        }
      }
    }, [_c('div', {
      staticClass: "order-title",
      attrs: {
        "slot": "title"
      },
      slot: "title"
    }, [_c('span', {
      staticStyle: {
        "margin-right": "10px"
      }
    }, [_vm._v(_vm._s(order.client.name))]), _c('span', {
      staticClass: "order-num"
    }, [_vm._v(_vm._s("(".concat(order.num, ")")))])]), _c('div', {
      attrs: {
        "slot": "label"
      },
      slot: "label"
    }, [_c('span', {
      staticStyle: {
        "margin-right": "10px"
      }
    }, [_vm._v(_vm._s(order.created_at))]), _c('van-tag', {
      attrs: {
        "type": order.business_status === 1 ? 'success' : 'warning'
      }
    }, [_vm._v(" " + _vm._s(order.business_status === 1 ? '已备货' : '未备货') + " ")])], 1)])], 1);
  })], 2), _c('div', {
    staticClass: "fixed-bottom-btn"
  }, [_c('div', {
    staticClass: "btn-container"
  }, [_c('van-checkbox', {
    staticClass: "check-all",
    on: {
      "click": _vm.handleCheckAll
    },
    model: {
      value: _vm.checkAll,
      callback: function callback($$v) {
        _vm.checkAll = $$v;
      },
      expression: "checkAll"
    }
  }, [_vm._v("全选")]), _c('van-button', {
    attrs: {
      "disabled": _vm.checkOrders.length === 0,
      "round": "",
      "line": ""
    },
    on: {
      "click": _vm.handleTake
    }
  }, [_vm._v(" 确认提货 "), _vm.checkOrders.length > 0 ? _c('span', {
    staticClass: "confirm-tips"
  }, [_vm._v("(" + _vm._s(_vm.checkOrders.length) + "张订单)")]) : _vm._e()])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <my-nav-bar
      :title="businessName"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-fixed-container safe-area-padding-bottom">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-checkbox-group v-for="order in list" :key="order.id" v-model="checkOrders" class="order">
        <van-checkbox :name="order.id" class="order-check" />
        <van-cell
          is-link
          center
          @click="toDetailView(order)"
        >
          <div slot="title" class="order-title">
            <span style="margin-right: 10px;">{{ order.client.name }}</span>
            <span class="order-num">{{ `(${order.num})` }}</span>
          </div>
          <div slot="label">
            <span style="margin-right: 10px;">{{ order.created_at }}</span>
            <van-tag :type="order.business_status === 1 ? 'success' : 'warning'">
              {{ order.business_status === 1 ? '已备货' : '未备货' }}
            </van-tag>
          </div>
        </van-cell>
      </van-checkbox-group>
    </div>
    <div class="fixed-bottom-btn">
      <div class="btn-container">
        <van-checkbox v-model="checkAll" class="check-all" @click="handleCheckAll">全选</van-checkbox>
        <van-button :disabled="checkOrders.length === 0" round line @click="handleTake">
          确认提货
          <span v-if="checkOrders.length > 0" class="confirm-tips">({{ checkOrders.length }}张订单)</span>
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getOrders, finishTake } from '@/api/business-take'

export default {
  components: { myNavBar },
  data() {
    return {
      businessName: this.$route.query.businessName,
      listQuery: {
        business_id: this.$route.query.businessId
      },
      list: [],
      showEmpty: false,
      checkAll: false,
      checkOrders: []
    }
  },
  watch: {
    checkOrders() {
      this.checkAll = this.checkOrders.length === this.list.length
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.beginLoad()
      getOrders(this.listQuery).then(res => {
        this.list = res.data
        this.showEmpty = this.list.length === 0
        this.checkOrders = this.list.map(order => order.id)
        this.endLoad()
      })
    },
    toDetailView(order) {
      this.$router.push({
        path: 'business-take-detail',
        query: {
          orderId: order.id,
          businessId: this.listQuery.business_id,
          clientName: order.client.name
        }
      })
    },
    handleTake() {
      // 检查是否有选中未备货的订单
      const notReadyOrders = []
      this.list.forEach(order => {
        if (order.business_status !== 0) { return }
        const hasOrderId = this.checkOrders.find(orderId => order.id === orderId)
        if (hasOrderId) {
          notReadyOrders.push(hasOrderId)
        }
      })
      if (notReadyOrders.length > 0) {
        this.confirm('检测到有未备货的订单，请与商家核实！', '提示').then(() => {
          this.handleTakeConfirm()
        })
      } else {
        this.handleTakeConfirm()
      }
    },
    handleTakeConfirm() {
      this.beginLoad()
      const data = {
        business_id: this.listQuery.business_id,
        order_ids: this.checkOrders
      }
      finishTake(data).then(res => {
        this.success(res.msg)
        this.$router.back()
      })
    },
    handleCheckAll() {
      if (this.checkAll) {
        this.checkOrders = this.list.map(order => order.id)
      } else {
        this.checkOrders = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .order {
    background-color: #fff;
    display: flex;
    border-bottom: 1px solid #f2f2f2;
    .order-check {
      padding: 10px;
      flex-shrink: 0;
    }
  }

  .order-title {
    word-break: break-all;
  }
  .order-num {
    color: #989898;
    font-size: 13px;
  }
  .btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    .van-button {
      height: 35px;
    }
  }
</style>
